import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import br from './languages/br'
import en from './languages/en'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { en, br },
    fallbackLng: 'br',
    // eslint-disable-next-line no-undef
    debug: process.env.NODE_ENV !== 'production',
  })

export default i18n
