/* eslint-disable no-undef */
import React from 'react'
import { useTranslation } from 'react-i18next'

import axios from 'axios'

import { apiEndpoint } from '../config/apiEndpoint'
import { getHeaders } from '../helpers'
import useInternalImpersonation from './useInternalImpersonation'

const api = axios.create()

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401 &&
      !['/login', '/public'].some((item) => window.location.pathname.includes(item))
    ) {
      localStorage.removeItem('uid')
      localStorage.removeItem('token')
      localStorage.removeItem('Authorization')
      localStorage.removeItem('client')
      window.location.href = '/login'
    }

    return Promise.reject(error)
  },
)

const formatParams = (currentLanguage, config) => ({
  params: {
    ...(currentLanguage !== 'pt-BR' && { locale: currentLanguage }),
    ...(Boolean(config?.params) && { ...config.params }),
  },
  ...config,
})

const injectUrl = (url, inputs) => {
  const regex = /:\w*/g
  const newUrl = url.replace(regex, (match) => inputs[match.replace(':', '')])
  return `${apiEndpoint}${newUrl}`
}

const useLoadingState = () => {
  const [result, setResult] = React.useState(undefined)
  const [loading, setLoading] = React.useState(undefined)
  return [loading, setLoading, result, setResult]
}

export const useApi = (path, method, params) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const call = async (body, localParams, overlapParams, source) => {
    setLoading(() => true)

    const allParams = { ...params, ...overlapParams }
    for (let param in allParams) {
      allParams[param] || delete allParams[param]
    }

    const compositeUrl = `${apiEndpoint}${path.toUrl(allParams)}`
    const localParamsFormatted = i18n.language !== 'pt-BR' ? { locale: i18n.language, ...localParams } : localParams
    try {
      const response = await api({
        ...(source && { cancelToken: source.token }),
        method: method || 'GET',
        url: compositeUrl,
        data: body,
        params: localParamsFormatted,
        headers: impersonationHeaders || getHeaders(),
      })
      setResult(() => response)

      return Promise.resolve(response.data)
    } catch (error) {
      if (error?.response?.data) {
        error.response.error = error.response.data
        delete error.response.data
      }
      setResult(() => error.response)
      return Promise.reject(error)
    } finally {
      setLoading(() => false)
    }
  }

  return [call, loading === true, result]
}

export const useGet = (url) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const call = async (params) => {
    setLoading(() => true)

    const compositeUrl = `${apiEndpoint}${url}`

    try {
      const response = await axios.get(params && params.inputs ? injectUrl(url, params.inputs) : compositeUrl, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, params.config || {}),
      })

      setResult(() => response)
      setLoading(() => false)
      return Promise.resolve(response.data)
    } catch (error) {
      setLoading(() => false)
      process.env.NODE_ENV !== 'production' && console.warn(error)
      return Promise.reject('error')
    }
  }

  return [call, loading === true, result]
}

export const useDelete = (url) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const call = async (params) => {
    setLoading(() => true)
    const compositeUrl = `${apiEndpoint}${url}`
    try {
      const response = await api.delete(params && params.inputs ? injectUrl(url, params.inputs) : compositeUrl, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, params.config || {}),
      })

      setResult(() => response)
      setLoading(() => false)
      return response.data
    } catch (error) {
      setLoading(() => false)
      setResult(error)
      process.env.NODE_ENV !== 'production' && console.warn(error)
      return undefined
    }
  }

  return [call, loading === true, result]
}

export const usePost = (url, opts = false) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const call = async ({ data, config, inputs }) => {
    setLoading(() => true)

    const compositeUrl = `${apiEndpoint}${url}`
    try {
      const response = await api.post(inputs ? injectUrl(url, inputs) : compositeUrl, data, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, config || {}),
      })

      setResult(() => response)
      setLoading(() => false)

      if (opts) return Promise.resolve(response)
      else return Promise.resolve({ ...response.data, meta: response })
    } catch (error) {
      setLoading(() => false)
      setResult(() => error.response)
      process.env.NODE_ENV !== 'production' && Promise.reject(error)
    }
  }

  return [call, loading === true, result]
}

export const usePut = (url) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const call = async ({ data, config, inputs }) => {
    setLoading(() => true)

    const compositeUrl = `${apiEndpoint}${url}`
    try {
      const response = await api.put(inputs ? injectUrl(url, inputs) : compositeUrl, data, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, config || {}),
      })

      setResult(() => response)
      setLoading(() => false)

      return response.data
    } catch (error) {
      setLoading(() => false)
      setResult(() => error.response)
      process.env.NODE_ENV !== 'production' && console.warn(error)
      return undefined
    }
  }

  return [call, loading === true, result]
}
