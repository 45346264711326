import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
`

export const Row = styled.div`
  display: flex;
  align-items: ${({ align }) => align || 'unset'};
  margin: ${({ margin }) => margin || 'unset'};
  justify-content: ${({ justify }) => justify || 'unset'};
`

export const Col = styled(Row)`
  flex-direction: column;
  flex: ${({ flex = 0 }) => flex};
  ${(props) => props.fullWidth && 'width: 100%;'}
`

export const Space = styled.div`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`

export default Flex
