import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ClickAwayListener, Grow, Popper, List, Button as MuiButton } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { NotificationsNone } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import _ from 'lodash'
import styled from 'styled-components'

import EmptyBlox from 'common/assets/images/blox-empty-state.svg'
import { useApi } from 'common/hooks/useApi'

import { UserNotificationsMarkAllEndpoint } from 'routes/locations/endpoints/userNotifications'

import { useRetrieve } from '../hooks/requestHooks'
import { buildUrl } from '../util/buildUrl'

const NotificationsIndicator = () => {
  const { t } = useTranslation()
  const anchorEl = useRef(null)
  const [notficationOpen, setNotificationOpen] = useState(false)
  const [visible, setVisible] = useState(3)
  const [quantity, setQuantity] = useState(null)
  const [anchorEel, setAnchorEls] = useState(null)
  const [hidden, setHidden] = useState(false)

  const [getNotifications, , resultNotifications] = useRetrieve(
    { listUrl: () => buildUrl('/v1/student/notifications') },
    'many',
    {},
  )

  const [updateNotification] = useApi(UserNotificationsMarkAllEndpoint, 'POST')

  const markAllRead = async () => {
    setAnchorEls(null)
    await updateNotification()
    getNotifications()
  }

  const handleNotificationClick = () => {
    handleCount()
    setNotificationOpen((prev) => !prev)
  }

  const handleCount = () => setQuantity(resultNotifications && resultNotifications.quantity - 3)

  if (resultNotifications !== undefined) {
    const result = _.filter(resultNotifications.notifications, (row) => row.read === true)
    localStorage.setItem('readCount', result.length)
  }

  const newNotifications =
    resultNotifications &&
    resultNotifications.notifications.reduce((acc, notification) => (!notification.read ? acc + 1 : acc), 0)

  return (
    <div ref={anchorEl}>
      <IconButton style={{ right: '0px' }} onClick={handleNotificationClick}>
        {resultNotifications && (
          <Badge badgeContent={newNotifications} color='primary' invisible={newNotifications === 0}>
            <NotificationsNone style={{ fill: 'white' }} />
          </Badge>
        )}
      </IconButton>
      <Popper
        open={notficationOpen}
        anchorEl={anchorEl.current}
        placement='bottom-end'
        transition
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={() => setNotificationOpen(false)}>
              <BodyContainer>
                <HeadContainer>
                  <LblNotification>{t('UserNotification:Label')}</LblNotification>
                  <div>
                    {resultNotifications && (
                      <LblNew>
                        {newNotifications} {t('UserNotification:Label-News')}
                      </LblNew>
                    )}
                    <IconButton size='small' onClick={(e) => setAnchorEls(e.currentTarget)}>
                      <MoreVertIcon style={{ fill: 'white' }} />
                    </IconButton>
                    <Menu
                      id='long-menu'
                      anchorEl={anchorEel}
                      keepMounted
                      open={!!anchorEel}
                      onClose={() => setAnchorEls(null)}
                      PaperProps={{
                        style: {
                          marginLeft: 30,
                          maxHeight: 48 * 4.5,
                          width: '20ch',
                          marginTop: 20,
                        },
                      }}
                    >
                      <MenuItem onClick={() => markAllRead()}>
                        <LabelBrandRead>{t('UserNotification:mark_all_read')}</LabelBrandRead>
                      </MenuItem>{' '}
                    </Menu>
                  </div>
                </HeadContainer>
                {resultNotifications && resultNotifications.notifications.length === 0 ? (
                  <EmptyContainer>
                    <img src={EmptyBlox} alt='empty blox' />
                    <EmptyTitle>{t('UserNotification:is_not')}</EmptyTitle>
                  </EmptyContainer>
                ) : (
                  <Container big={!!hidden}>
                    <List>
                      {resultNotifications.notifications.slice(0, visible).map((notification, index) => (
                        <div key={index} style={{ borderBottom: '1px solid #E4E7EB', paddingBottom: 10 }}>
                          <NotificationHeader>
                            {notification.url ? (
                              <ListItemMenssageLink href={notification.url} target='_blank'>
                                {notification.title}
                              </ListItemMenssageLink>
                            ) : (
                              <ListItemMenssage>{notification.title}</ListItemMenssage>
                            )}
                            <ListItemMenssageDate>{notification.created_at}</ListItemMenssageDate>
                          </NotificationHeader>
                          <ItemMenssageBody>{notification.message}</ItemMenssageBody>
                        </div>
                      ))}
                    </List>
                  </Container>
                )}
                {!hidden && resultNotifications && resultNotifications.notifications.length > 0 ? (
                  <Button
                    disabled={!quantity}
                    onClick={() => {
                      if (quantity) {
                        setVisible((preValue) => preValue + quantity)
                        setHidden(true)
                      }
                    }}
                  >
                    {t('UserNotification:see_all')}
                  </Button>
                ) : null}
              </BodyContainer>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default NotificationsIndicator

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  width: 100%;
`

const EmptyTitle = styled.h1`
  font-weight: normal;
  padding-top: 20px;
  text-align: center;
`

const BodyContainer = styled.div`
  top: 72px;
  left: 86px;
  width: 335px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
`

const LabelBrandRead = styled.div`
  font-family: Roboto;
  text-align: left;
  font: normal normal normal 12px/10px Roboto;
  -webkit-letter-spacing: 0.03px;
  -moz-letter-spacing: 0.03px;
  -ms-letter-spacing: 0.03px;
  letter-spacing: 0.03px;
  color: #30313c;
  opacity: 1;
`

const HeadContainer = styled.div`
  background: ${({ theme }) => theme.palette.primary.dark} !important;
  border-radius: 2px 2px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 10px;
`

const LblNotification = styled.label`
  letter-spacing: 0.03px;
  color: #ffffff;
  font-size: 12px;
`

const LblNew = styled.label`
  letter-spacing: 0.03px;
  color: #ffffff;
  font-size: 12px;
  margin-right: 5px;
`

const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
`

const ListItemMenssageLink = styled.a`
  color: #4192e0;
  display: block;
  font-weight: 450;
`

const ListItemMenssageDate = styled.p`
  color: #4192e0;
  margin: 0;
`

const ListItemMenssage = styled.p`
  color: #39414a;
  font-weight: 450;
`

const ItemMenssageBody = styled.p`
  font-size: 12px;
  margin: 10px;
  word-break: break-word;
`
const Button = styled(MuiButton)`
  background-color: white !important;
  color: ${({ theme }) => theme.palette.primary.main} !important;
  padding: 10px 93px !important;
  font-size: 10px !important;
  text-transform: uppercase;
`

const Container = styled.div`
  height: ${({ big }) => (big ? 354 : 317)}px;
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  ::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
  }
  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }
`
