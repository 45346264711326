import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,

    '@media (max-width: 468px)': {
      width: 'calc(100% - 90px)',
      alignItems: 'flex-start',
      marginLeft: 0,
      marginTop: 18,
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomWidth: 2,
      borderColor: '#f8f8f8',
    },
  },
  select: {
    width: 142,
    color: '#f8f8f8',
    textTransform: 'capitalize',
    '&:before': {
      borderBottomWidth: 2,
      borderColor: '#f8f8f8',
    },

    '@media (max-width: 468px)': {
      width: '100%',
    },
  },
  icon: {
    fill: '#f8f8f8',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
})
