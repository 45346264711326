import React from 'react'
import { toast } from 'react-toastify'

import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flexWrap: 'nowrap',
  },
}))

const MySnackbarContentWrapper = (props) => {
  const classes = useStyles1()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes.content, classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key='close' aria-label='close' color='inherit' onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}

const Notify = ({ variant, message, closeToast, duration, bottomDisplacement }) => {
  return (
    <div>
      <CustomSnackBar
        open={true}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={duration}
        onClose={closeToast}
        bottomDisplacement={bottomDisplacement}
      >
        <MySnackbarContentWrapper onClose={closeToast} variant={variant} message={message} />
      </CustomSnackBar>
    </div>
  )
}

const CustomSnackBar = styled(Snackbar)`
  &.MuiSnackbar-root {
    z-index: 99999999999999;
  }
  &.MuiSnackbar-anchorOriginBottomCenter {
    bottom: ${(props) => 24 + props.bottomDisplacement}px;
  }
`

export const notifyToast = (message, variant, duration, bottomDisplacement) => {
  toast(
    <Notify
      variant={variant || 'success'}
      message={message}
      duration={duration || 3000}
      bottomDisplacement={bottomDisplacement || 0}
    />,
  )
}
