import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'

import MomentUtils from '@date-io/moment'
import { ThemeProvider, createTheme as createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { generalLinks } from 'modules/admin/general-parameters/links'
import { pedagogicalLinks } from 'modules/admin/pedagogical-parameters/links'
import { ThemeProvider as StyledProvider } from 'styled-components'

import Footer from 'common/components/Footer'
import AdminSidebar, { ResponsiveContainer } from 'common/components/navbar/AdminSidebar'
import BloxNavbar from 'common/components/navbar/BloxNavbar'
import MainNavbar from 'common/components/navbar/MainNavbar'
import { useInfo } from 'common/hooks/useInfo'
import store from 'common/store'

import 'moment/locale/pt-br'

const Routes = React.lazy(() => import('routes'))

const App = () => {
  const [userInfo] = useInfo()

  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage('pt-BR')
  }, [])

  const theme = createMuiTheme({
    palette: {
      ...(userInfo?.data?.header_configuration &&
      userInfo?.data?.header_configuration?.custom_color &&
      userInfo?.data?.header_configuration?.color
        ? { primary: { main: userInfo.data.header_configuration.color } }
        : { primary: { main: 'rgba(68,138,255,1)' } }),
      secondary: {
        main: '#D86161',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 993,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      useNextVariants: true,
    },
  })

  useEffect(() => {
    if (userInfo?.data) {
      localStorage.removeItem('@blox:recaptcha-attempts')
    }

    if (['coordinator', 'admin'].includes(userInfo?.data?.role)) {
      document.querySelector('#feedback-form').style.display = 'block'
      document.querySelector('#feedback-menu').style.display = 'block'
      document.querySelector('#knowledge-base').style.display = 'block'
    } else {
      document.querySelector('#feedback-form').style.display = 'none'
      document.querySelector('#feedback-menu').style.display = 'none'
      document.querySelector('#knowledge-base').style.display = 'none'
    }
  }, [userInfo])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
      <ThemeProvider theme={theme}>
        <StyledProvider theme={theme}>
          <Provider store={store}>
            {!(
              window.location.pathname.includes('/public') ||
              window.location.pathname.includes('/validate_certificate') ||
              window.location.pathname.includes('/student/validate_certificate/')
            ) && (
              <>
                {['student', 'professor', 'coordinator', 'admin'].includes(userInfo?.data?.role) ? (
                  <BloxNavbar />
                ) : (
                  <MainNavbar />
                )}
              </>
            )}
            <Suspense>
              {userInfo?.data?.role === 'admin' ? (
                window.location.pathname.includes('/admin/general-parameters') ||
                window.location.pathname.includes('/admin/pedagogical-parameters') ? (
                  <ResponsiveContainer>
                    <AdminSidebar
                      links={
                        window.location.pathname.includes('/admin/general-parameters') ? generalLinks : pedagogicalLinks
                      }
                    />
                    <Routes />
                  </ResponsiveContainer>
                ) : (
                  <Routes />
                )
              ) : (
                <Routes />
              )}
            </Suspense>
            {!(
              window.location.pathname.includes('/login') ||
              window.location.pathname.includes('/public') ||
              window.location.pathname.includes('/validate_certificate') ||
              window.location.pathname.includes('/student/validate_certificate/')
            ) && <Footer year={2021} />}
            <ReactTooltip className='tooltip-extra-class' effect='float' multiline />
          </Provider>
        </StyledProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}
export default withRouter(App)
