import { hexToRgb } from '@material-ui/core/styles'

export const changeColor = ({ color, opacity = 1, brightness = 0.9 }) => {
  const isRgb = new RegExp(/^rgba*(.+)/).test(color)

  if (isRgb) {
    const [r, g, b, a = 1] = color.match(/[\d]+/g)
    return `rgba(${r * brightness}, ${g * brightness}, ${b * brightness}, ${a * opacity})`
  }

  return color
}

export const convertToRgb = (color) => {
  const isRgb = new RegExp(/^rgba*(.+)/).test(color)
  if (isRgb) return color
  return hexToRgb(color)
}

export const getMainColor = (color) => {
  const [red, green, blue] = convertToRgb(color).match(/[\d]+/g)
  const color_obj = [
    { name: 'red', value: red },
    { name: 'green', value: green },
    { name: 'blue', value: blue },
  ]
  const sorted = color_obj.sort((a, b) => b.value - a.value)

  return `${sorted[0].value - sorted[1].value >= 90 ? sorted[0].name : 'not mapped'}`
}
