import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'

import {
  Avatar,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Cog from '@material-ui/icons/Settings'
import { deleteFromStorage } from '@rehooks/local-storage'
import styled from 'styled-components'

import AvatarImage from '../../assets/images/avatar.png'
import BloxLogoImage from '../../assets/images/logo_blox.svg'
import { useInfo } from '../../hooks/useInfo'
import useWindowDimensions from '../../hooks/useWindowSize'
import Flex from '../Flex'
import NotificationsIndicator from '../NotificationsIndicator'
import AdminNavbar from './AdminNavbar'
import CoordinatorNavbar from './CoordinatorNavbar'
import ImpersonateLabel from './ImpersonateLabel'
import ProfessorNavbar from './ProfessorNavbar'
import StudentNavbar, { studentLinks } from './StudentNavbar'

const roles = {
  STUDENT: 'student',
  PROFESSOR: 'professor',
  COORDINATOR: 'coordinator',
  ADMIN: 'admin',
}

const CurrentUserNavbar = () => {
  const [userInfo] = useInfo()
  const navbars = {
    [roles.STUDENT]: <StudentNavbar />,
    [roles.PROFESSOR]: <ProfessorNavbar />,
    [roles.COORDINATOR]: <CoordinatorNavbar />,
    [roles.ADMIN]: <AdminNavbar />,
  }

  return <ul className='navbar'>{userInfo.data && navbars[userInfo.data.role]}</ul>
}

const MainNavbar = ({ history }) => {
  const [userInfo] = useInfo()
  const userActionsRef = React.useRef(null)
  const responsiveMenuRef = React.useRef(null)
  const impersonateControlsRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isImpersonating = localStorage.getItem('user-impersonating')

  const mobile = width < 700

  const logout = () => {
    const redirectTo = userInfo.data.institution.options.logout_redirect_to

    deleteFromStorage('token')
    deleteFromStorage('Authorization')
    deleteFromStorage('client')
    deleteFromStorage('uid')
    deleteFromStorage('userInfo')

    deleteFromStorage('user-impersonating-headers')
    deleteFromStorage('user-impersonating')

    if (redirectTo !== undefined) {
      window.location.href = redirectTo
    } else {
      history.push('/')
    }
  }

  const goto = (path) => () => {
    setOpen(!open)
    history.push(path)
  }

  if (history.location.pathname.includes('login')) return null

  if (!history.location.pathname.includes('login') && userInfo?.data) {
    const dividedName = userInfo.data.name.split(' ')
    const firstName = dividedName[0]
    const lastName = dividedName[dividedName.length - 1]

    return (
      <>
        {/* Set a impersonate "label" on the UI */}
        {isImpersonating && (
          <ImpersonateContainer ref={impersonateControlsRef}>
            <ImpersonateLabel />
          </ImpersonateContainer>
        )}
        <Topnav
          impersonate={
            isImpersonating && impersonateControlsRef.current ? impersonateControlsRef.current.clientHeight : 0
          }
          className='topnav'
        >
          <div className='logo-placeholder'>
            <Link to='/'>
              <LogoImage src={(userInfo.data && userInfo.data.institution.logo_url) || BloxLogoImage} />
            </Link>
          </div>

          <div className='links'>
            <CurrentUserNavbar />
          </div>
          <div style={{ marginRight: -15 }}>
            {userInfo.data.role.includes('student') && !history.location.pathname.includes('login') && (
              <NotificationsIndicator />
            )}
          </div>

          <div className='user-actions' ref={userActionsRef} onClick={() => setOpen(!open)}>
            <div className='open-profile-button' style={{ cursor: 'pointer' }}>
              <i className='material-icons'>arrow_drop_down</i>
              <BloxAvatar
                src={
                  userInfo.data.picture_url === null || typeof userInfo.data.picture_url === 'undefined'
                    ? AvatarImage
                    : userInfo.data.picture_url
                }
              />
            </div>
          </div>

          <button ref={responsiveMenuRef} onClick={() => setOpen(!open)} id='menu-icon'>
            <i className='material-icons'>menu</i>
          </button>
          <Popper open={open} anchorEl={!mobile ? userActionsRef.current : responsiveMenuRef.current} transition>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuList>
                      {userInfo.data.role.includes('student') && (
                        <>
                          <MenuItem>
                            <Flex justifyContent='space-around'>
                              <Flex flexDirection='column'>
                                <BloxAvatar
                                  src={
                                    userInfo.data.picture_url === null ||
                                    typeof userInfo.data.picture_url === 'undefined'
                                      ? AvatarImage
                                      : userInfo.data.picture_url
                                  }
                                />

                                <h5 style={{ margin: 0 }}>{firstName}</h5>

                                <h6 style={{ margin: 0 }}>{lastName}</h6>
                              </Flex>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'inline-block',
                                    fontSize: 12,
                                    textTransform: 'uppercase',
                                    color: '#636063',
                                    marginTop: 15,
                                    marginLeft: 30,
                                  }}
                                >
                                  {userInfo.data.institution.name}
                                  <br />
                                  {userInfo.data.current_course && (
                                    <span
                                      style={{
                                        fontSize: 10,
                                        textTransform: 'none',
                                      }}
                                    >
                                      {userInfo.data.current_course.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Flex>
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={goto('/student/settings')}>
                            <ListItemIcon>
                              <Cog />
                            </ListItemIcon>
                            <ListItemText inset primary={'Configurações'} />
                          </MenuItem>
                          {mobile &&
                            studentLinks(t).map((link, index) => (
                              <MenuItem key={index} onClick={goto(link.path)}>
                                <ListItemText inset primary={link.text} />
                              </MenuItem>
                            ))}
                        </>
                      )}
                      <Divider />
                      <MenuItem onClick={() => logout()}>
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText inset primary='Logout' />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Topnav>
      </>
    )
  } else return null
}

const LogoImage = styled.img`
  height: 52px;
  margin-left: 2vw;
  width: auto;
`

const BloxAvatar = styled(Avatar)`
  cursor: pointer;
`
const ImpersonateContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 99999;
`
const Topnav = styled.div`
  padding-top: ${({ impersonate }) => `calc(${impersonate}px)`} !important;
`

export default withRouter(MainNavbar)
