import React from 'react'

import { Tooltip } from '@material-ui/core'
import styled from 'styled-components'

const ActionButtons = ({
  studentsButton,
  resetStudentsButton,
  onEdit,
  hideDelete,
  chooseBloxButton,
  onCacheOffering,
  onDelete,
  onSyncStudent,
  onImpersonateStudent,
  onCloseImpersonate,
  buttonStyle,
  ...props
}) => {
  return (
    <div>
      {onEdit && (
        <Tooltip title={props.tooltip}>
          <Value>
            <RoundedButton onClick={onEdit} className='blox-button waves-effect waves-light' {...props}>
              <i className='material-icons left'>edit</i>
            </RoundedButton>
          </Value>
        </Tooltip>
      )}
      {onDelete && !hideDelete && (
        <RoundedButton className='blox-button waves-effect waves-light' onClick={onDelete}>
          <i className='material-icons left'>delete</i>
        </RoundedButton>
      )}
      {studentsButton && (
        <RoundedButton onClick={studentsButton} className='blox-button waves-effect waves-light'>
          <i className='material-icons'>supervisor_account</i>
        </RoundedButton>
      )}
      {resetStudentsButton && (
        <Tooltip title={resetStudentsButton.tooltip}>
          <Value>
            <RoundedButton onClick={resetStudentsButton.action} className='blox-button waves-effect waves-light'>
              <i className='material-icons'>autorenew</i>
            </RoundedButton>
          </Value>
        </Tooltip>
      )}
      {chooseBloxButton && (
        <Tooltip title={chooseBloxButton.tooltip}>
          <Value>
            <RoundedButton onClick={chooseBloxButton.action} className='blox-button waves-effect waves-light'>
              <i className='material-icons'>import_export</i>
            </RoundedButton>
          </Value>
        </Tooltip>
      )}
      {onCacheOffering && (
        <Tooltip title={onCacheOffering.tooltip}>
          <Value>
            <RoundedButton
              onClick={onCacheOffering.action}
              disabled={onCacheOffering.value === onCacheOffering.cached}
              className='blox-button waves-effect waves-light'
            >
              <i className='material-icons'>360</i>
            </RoundedButton>
          </Value>
        </Tooltip>
      )}
      {onSyncStudent && (
        <Tooltip title={onSyncStudent.tooltip}>
          <Value>
            <RoundedButton {...props} onClick={onSyncStudent.action} className='blox-button waves-effect waves-light'>
              <i className='material-icons'> system_update_alt </i>
            </RoundedButton>
          </Value>
        </Tooltip>
      )}
      {onImpersonateStudent && (
        <Tooltip title={onImpersonateStudent.tooltip}>
          <Value>
            <RoundedButton
              {...props}
              onClick={onImpersonateStudent.action}
              className='blox-button waves-effect waves-light'
            >
              <i className='material-icons'> remove_red_eye </i>
            </RoundedButton>
          </Value>
        </Tooltip>
      )}
      {onCloseImpersonate && (
        <Value>
          <RoundedButton
            style={buttonStyle}
            onClick={onCloseImpersonate.action}
            className='blox-button waves-effect waves-light'
          >
            <i className='material-icons'> close </i>
          </RoundedButton>
        </Value>
      )}
    </div>
  )
}

export default ActionButtons

const Value = styled.span`
  margin-left: 10px;
`

const RoundedButton = styled.button`
  width: 40px !important;
  border-radius: 20px !important;
`
