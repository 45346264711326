import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { environment } from 'common/config/sentry'

import * as serviceWorker from './common/service-workers/serviceWorker'
import App from './modules'

// import('react-toastify/dist/ReactToastify.css')
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './translations'
import('./common/assets/css/index.css')
import('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css')

const env = environment()

if (env) {
  Sentry.init({
    environment: env,
    dsn: 'https://7ea0a1bcb9c24c32a26471dbebe80619@o134678.ingest.sentry.io/6342462',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  })
}

toast.configure()
// Global State Tree

// Log token for debugging
// eslint-disable-next-line no-undef
process.env.NODE_ENV !== 'production' && console.log(localStorage.getItem('token'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
serviceWorker.unregister()

const root = createRoot(document.getElementById('root'))

root.render(
  <Suspense fallback={<span />}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ToastContainer />
  </Suspense>,
)
